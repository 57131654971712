import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import SEO from '../components/seo';
import Layout from '../components/layout';
import Page from '../components/Page';

import FeaturedPost from '../components/featured-post';
import LinkGroup from '../components/link-group';
import OtherPosts from '../components/other-posts';

const IndexPage = ({ data }) => {
  const posts = data.wp.posts.edges.map(({ node }) => node);

  return (
    <Layout>
      <SEO title="Home" />
      <Page.Wrapper>
        <FeaturedPost post={posts[0]} />
        <LinkGroup />
        <Page.Content full>
          <Page.Title>Other Posts</Page.Title>
          <OtherPosts posts={posts} />
        </Page.Content>
      </Page.Wrapper>
    </Layout>
  );
};

IndexPage.defaultProps = {
  data: {
    wp: {
      posts: {
        edges: [],
      },
    },
  },
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    wp: PropTypes.shape({
      posts: PropTypes.shape({
        edges: PropTypes.array,
      }),
    }),
  }),
};

export default IndexPage;

export const postsQuery = graphql`
  query PostQuery {
    wp {
      posts {
        edges {
          node {
            slug
            featuredImage {
              sourceUrl
            }
            title
            author {
              name
            }
            date
          }
        }
    }
  }
}
`;
