import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import { Button } from 'semantic-ui-react';

import formatDate from '../utils/formatDate';

import theme from '../styles/theme';

const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;
const PostCard = styled(Link)`
  width: 400px;
  height: 300px;
  margin: 20px;
  background: linear-gradient(to right, rgba(45, 52, 54,0.7), rgba(45, 52, 54,0.7) 25%,
  rgba(45, 52, 54,0.7)),url('${(props) => (props.background ? props.background : '')}');
  background-size: cover;
  background-position: center;
  outline: 2px solid rgba(255,255,255,.5);
  outline-offset: -10px;
  text-align: center;
  position: relative;

`;
const PostTitle = styled.p`
  margin-top: 115px !important;
  margin-bottom: 0px;
  font-size: 35px;
  font-weight: 700;
  color: ${theme.colors.white} !important;

  ${theme.breakpoints.tablet} {
    margin-top: 100px !important;
    font-size: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }

`;
const PostMeta = styled.p`
  position: absolute;
  bottom: 10px;
  left: 20px;
  color: ${theme.colors.white} !important;
  font-size: 14px;
  font-weight: 100;
`;
class OtherPosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      postToShow: props.posts.slice(0, 5),
      lastPhotoShown: 5,
      arePostRemaining: props.posts.length > 0,
    };

    this.showMorePost = this.showMorePost.bind(this);
  }

  showMorePost() {
    const { lastPhotoShown: index, postToShow } = this.state;
    const { posts } = this.props;
    const newPosts = posts.slice(index, index + 5);
    this.setState({
      postToShow: [...postToShow, ...newPosts],
      lastPhotoShown: index + 5,
      arePostRemaining: index + 5 <= posts.length,
    });
  }


  render() {
    const { postToShow: posts, arePostRemaining } = this.state;
    return (
      <>
        <PostsContainer>
          {posts.slice(1).map((post) => (
            <PostCard key={post.slug} to={`/posts/${post.slug}`} background={post.featuredImage.sourceUrl}>
              {/* <PostImage src={post.featuredImage.sourceUrl}/> */}
              <PostTitle>{post.title}</PostTitle>
              <PostMeta>
                {post.author.name}
                {' '}
                |
                {' '}
                {formatDate(post.date)}
              </PostMeta>
            </PostCard>
          ))}
        </PostsContainer>
        <div style={{ textAlign: 'center', marginTop: '30px' }}>
          {arePostRemaining && <Button size="huge" onClick={this.showMorePost}>See Older Posts</Button>}
        </div>
      </>
    );
  }
}
OtherPosts.propTypes = {
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      featuredImage: PropTypes.shape({
        sourceUrl: PropTypes.string.isRequired,
      }),
      slug: PropTypes.string.isRequired,
      author: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }),
    }),
  ),
};

OtherPosts.defaultProps = {
  posts: [],
};

export default OtherPosts;
