import React from 'react';
import { Link } from 'gatsby';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import theme from '../styles/theme';

const LinkSection = styled.div`
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  background: #F0F0F0;
  justify-content: space-around;
  margin-bottom: 40px;
  color: ${theme.colors.black} !important;
  ${theme.breakpoints.mobile}{
    display: block;
  }
`;

const StyledLink = styled(Link)`
  font-size: 30px;
  font-weight: 100;
  display: block;
  color: ${theme.colors.black};
  margin-top: 30px;

  :hover {
    color: grey;
  }
`;


const LinkGroup = styled.div`
  text-align: center;
  display: block;
  color: ${theme.colors.black};
  :hover {
    color: ${theme.colors.lightGray};
  }

  ${theme.breakpoints.mobile}{
    margin-top: 80px;
  }
`;

const LinkGroupComponent = () => (
  <LinkSection>
    <LinkGroup>
      <StyledLink to="/about">
        <Icon size="huge" name="info circle" />
        <br />
        <br />
        About Us
        <Icon size="small" name="chevron right" />
      </StyledLink>
    </LinkGroup>
    <LinkGroup>
      <StyledLink to="/resources">
        <Icon size="huge" name="book" />
        <br />
        <br />
        Resouces
        <Icon size="small" name="chevron right" />
      </StyledLink>
    </LinkGroup>
    <LinkGroup>
      <StyledLink to="/countries">
        <Icon size="huge" name="globe" />
        <br />
        <br />
        Countries
        <Icon size="small" name="chevron right" />
      </StyledLink>
    </LinkGroup>
  </LinkSection>
);


export default LinkGroupComponent;
