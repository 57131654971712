import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import theme from '../styles/theme';
import Page from './Page';
import formatDate from '../utils/formatDate';


const FeaturedPost = styled.div`
  position: relative;
  color: ${theme.colors.white};
  background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) 25%,
  rgba(0, 0, 0, 0.5)),url(${(props) => (props.backgroundImg ? props.backgroundImg : '')});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 700px;
  max-width: 1500px;
  margin: auto;
  outline: 2px solid rgba(255,255,255,.5);
  outline-offset: -20px;

  ${theme.breakpoints.tablet} {
    height: 400px;
    width: 100%;
  }

  `;
const FeaturedPostTitle = styled.h2`
  font-size: 65px;
  font-weight: 700;
  margin: 0;

  ${theme.breakpoints.tablet} {
    font-size: 40px;
  }
`;
const FeaturedPostDate = styled.p`
  font-size: 20px;
  font-weight: 100;
  color: ${theme.colors.white} !important;
`;
const FeaturedPostContainer = styled(Page.Content)`
  text-align: center;
  padding: 50px 50px;

  ${theme.breakpoints.tablet} {
    padding: 20px 20px;
  }

  ${theme.breakpoints.mobile} {
    padding: 20px 5px;
  }
`;

const FeaturedPostMeta = styled.div`
    text-align: left;
    position: absolute;
    top: 50%;
    width: 75%;
    left: 96px;
    -webkit-transform: translate(0,-50%);
    transform: translate(0,-50%);
    font-weight: 400;

    ${theme.breakpoints.tablet} {
      width: 80%;
      left: 50px;
    }
`;
const FeaturedPostCTA = styled(Link)`
  padding: 10px 25px;
  border: 1px solid white;
  margin-top: 10px;
  font-size: 14px;
  color: ${theme.colors.white} !important;

  :hover {
    background: white;
    color: black !important;
  }
`;

const FeaturedPostComponent = ({ post }) => (
  <FeaturedPostContainer full>
    <FeaturedPost backgroundImg={post.featuredImage.sourceUrl}>
      <FeaturedPostMeta>
        <FeaturedPostTitle>{post.title}</FeaturedPostTitle>
        <FeaturedPostDate>
          {post.author.name}
          {' '}
          |
          {' '}
          {formatDate(post.date)}
        </FeaturedPostDate>
        <FeaturedPostCTA to={`/posts/${post.slug}`}>Read More &gt; </FeaturedPostCTA>
      </FeaturedPostMeta>
    </FeaturedPost>

  </FeaturedPostContainer>
);

FeaturedPostComponent.propTypes = {
  post: PropTypes.shape({
    date: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    featuredImage: PropTypes.shape({
      sourceUrl: PropTypes.string.isRequired,
    }),
    slug: PropTypes.string.isRequired,
    author: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

FeaturedPostComponent.defaultProps = {};

export default FeaturedPostComponent;
